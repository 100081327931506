.gm-style-cc {
  display: none;
}

.Select-control {
  @apply border-cool-gray-300 border-solid border-2 rounded-lg cursor-text !important;
  height: auto !important;
}
.Select-control:focus,
.Select-control:focus-within {
  @apply shadow-outline !important;
}
.Select-control .Select-input {
  height: 40px;
}
.Select-control .Select-placeholder,
.Select-control .Select-value {
  line-height: 40px !important;
}
.Select-control .Select-input > input {
  line-height: 24px;
}

.text-true-black {
  @apply text-black;
}

/* Sidekick */

.Sidekick {
  @apply bg-gray-200;
}
.Sidekick .btn-blue {
  background-color: #5850ec;
  border: none;
}
.Sidekick .btn-blue:hover {
  background-color: #6775f5;
  border: none;
}
.Sidekick .btn-magic {
  background: #5850ec;
}
.Sidekick .bg-cool-gray-600 {
  background: #5850ec;
}
