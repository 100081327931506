.Shake {
  animation-name: shake;
  animation-duration: .4s;
  animation-fill-mode: both;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }

  10%, 50%, 90% {
    transform: translateX(-10px);
  }

  30%, 70% {
    transform: translateX(10px);
  }
}

@keyframes slidein {
  from {
    margin-top: -50%;
  }

  to {
    margin-top: 0%;
  }
}

@keyframes slideout {
  from {
    margin-top: 0%;
  }

  to {
    margin-top: -50%;
  }
}

@keyframes slideup_soft {
  from {
    opacity: 0;
    margin-top: 2%;
  }

  to {
    opacity: 1;
    margin-top: 0%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein_delay {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-checkbox {
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1em;
  width: 1em;
  color: #4299e1;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .25rem;
  flex-shrink: 0;
  display: inline-block;
}

.form-checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-checkbox::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-checkbox:focus {
  border-color: #63b3ed;
  outline: none;
  box-shadow: 0 0 0 3px #4299e180;
}

.form-radio {
  -webkit-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1em;
  width: 1em;
  color: #4299e1;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: 100%;
  flex-shrink: 0;
  display: inline-block;
}

.form-radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-radio::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-radio:focus {
  border-color: #63b3ed;
  outline: none;
  box-shadow: 0 0 0 3px #4299e180;
}

.form-checkbox:checked:focus, .form-radio:checked {
  border-color: #0000;
}

body.tenant-tesla {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tenant-tesla .btn, .tenant-tesla .btn.btn-magic {
  text-transform: uppercase !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  background: none !important;
  border: 1px solid #fff !important;
  font-weight: 500 !important;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke !important;
  transition-duration: .1s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.tenant-tesla .btn:hover {
  background: radial-gradient(50% 50%, #6666 0%, #c4c4c400 100%) !important;
}

.tenant-tesla .btn.btn-blue, .tenant-tesla .btn.btn-magic {
  background: linear-gradient(#83838399 0%, #50505000 100%) !important;
}

.tenant-tesla .btn.btn-blue:hover, .tenant-tesla .btn.btn-magic:hover {
  background: linear-gradient( 180deg, #83838399 0%, #50505000 100% ) radial-gradient( 50% 50% at 50% 50%, #6666 0%, #c4c4c400 100% ) !important;
}

.tenant-tesla .Base {
  background: radial-gradient(50% 50%, #6666 0%, #c4c4c400 100%);
}

.tenant-tesla .Nav {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tenant-tesla .Nav .btn {
  border: none !important;
}

.tenant-tesla .text-black, .tenant-tesla .text-gray-700, .tenant-tesla .text-cool-gray-600, .tenant-tesla .text-cool-gray-700, .tenant-tesla .text-cool-gray-800, .tenant-tesla .text-cool-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.tenant-tesla .text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.tenant-tesla .bg-cool-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity));
}

.tenant-tesla .bg-cool-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.tenant-tesla .bg-cool-gray-100, .tenant-tesla .hover\:.bg-cool-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tenant-tesla .select-btn {
  --tw-border-opacity: 1 !important;
  border-width: 1px !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
  background-color: #0000 !important;
}

.tenant-tesla .Modal {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.tenant-tesla .bg-blue-100, .tenant-tesla .hover\:bg-blue-100:hover, .tenant-tesla .hover\:bg-cool-gray-100:hover {
  background-color: #5f5f5f;
}

.tenant-tesla .border-blue-300 {
  border-color: #5f5f5f;
}

.tenant-tesla .text-blue-600 {
  color: #fff;
}

.tenant-tesla .border-blue-600, .tenant-tesla .border-cool-gray-600, .tenant-tesla .border-cool-gray-400 {
  border-color: #fff;
}

.tenant-tesla {
  font-family: Gotham A, Gotham B;
  font-style: normal;
  font-weight: 500;
}

.tenant-tesla .ManagePolicy {
  color: #fff;
}

.tenant-tesla .ManagePolicy .bg-white {
  background-color: #313131;
}

.tenant-tesla .ManagePolicy .bg-gray-50 {
  background-color: #4c4c4c;
}

.tenant-tesla .ManagePolicy .text-gray-900, .tenant-tesla .ManagePolicy .text-gray-800, .tenant-tesla .ManagePolicy .text-gray-700, .tenant-tesla .ManagePolicy .text-gray-600, .tenant-tesla .ManagePolicy .text-gray-500 {
  color: #fff;
}

.tenant-tesla .ManagePolicy .text-indigo-600 {
  color: #bebbf9;
}

.tenant-tesla .ManagePolicy .bg-blue-600, .tenant-tesla .ManagePolicy .bg-blue-400 {
  background-color: #5e5e5e;
}

.tenant-tesla .bg-blue-300, .tenant-tesla .bg-blue-500 {
  background-color: #1b1b1b;
}

.tenant-tesla .text-blue-500 {
  color: #fff;
}

.tenant-tesla .form-checkbox {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity));
}

.tenant-tesla .bg-green-100 {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tenant-tesla .text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .tenant-tesla .ManagePolicy .sm\:bg-cool-gray-50 {
    background-color: #4e4e4e;
  }
}

.tenant-usaa .text-cool-gray-600, .tenant-usaa .text-cool-gray-700, .tenant-usaa .textbox, .tenant-usaa .Select-multi-value-wrapper, .tenant-usaa .Select-value, .tenant-usaa .Select-value-label, .tenant-usaa .Select-input, .tenant-usaa .Dropdown, .tenant-usaa .form-checkbox {
  color: #003a63 !important;
}

.tenant-usaa .btn-blue {
  background-color: #003a63;
  border: none;
}

.tenant-usaa .btn-blue:hover {
  background-color: #00668a;
  border: none;
}

.tenant-usaa .btn-magic {
  background: linear-gradient(#00668a 0%, #12395bcc 100%) !important;
}

.tenant-mercury .btn-blue {
  background-color: #991b1e;
  border: none;
}

.tenant-mercury.can-hover .btn-blue:hover, .tenant-mercury.can-hover .btn-blue.btn-disabled:hover {
  background-color: #77080a;
  border: none;
}

.tenant-mercury .btn-magic {
  background: linear-gradient(90deg, #941a1b 0%, #77080a 100%) !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9fa6b2;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #d2d6dc;
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.block-scroll {
  height: 100%;
  overflow: hidden;
}

.btn {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  touch-action: manipulation;
  border-radius: .5rem;
  margin: .5rem;
  padding: 1rem 1.5rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn:focus {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.can-hover .btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.can-hover .btn:active, .can-hover .btn.btn-subtle:active, .can-hover .btn.btn-blue:active, .can-hover .btn.btn-magic:active {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn.btn-disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn.btn-disabled.btn-magic {
  opacity: .5;
}

.can-hover .btn.btn-disabled:active {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.can-hover .btn.btn-disabled:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.btn-blue, .can-hover .btn-blue.btn-disabled:hover {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity));
}

.can-hover .btn-blue:hover {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

.btn-subtle, .can-hover .btn-subtle.btn-disabled:hover {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
  background-color: #0000;
}

.can-hover .btn-subtle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btn-blue.btn-subtle {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
  background-color: #0000;
}

.can-hover .btn-blue.btn-subtle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.btn-magic {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-image: linear-gradient(135deg, #58b1ff, #2b6fd4);
  border-style: none;
  transition-property: all;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.can-hover .btn-magic:hover:not(.btn-disabled), .can-hover .btn-magic:focus:not(.btn-disabled) {
  background-image: linear-gradient(135deg, #5fa5e2, #106ffb);
}

.btn-text {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity));
  background-color: #0000;
  align-self: center;
  padding: .5rem 1rem;
  font-weight: 500;
}

.can-hover .btn-text:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  background-color: #0000;
}

.select-btn {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  text-align: center;
  border-radius: .5rem;
  align-items: center;
  margin: .5rem;
  padding: 1rem;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.can-hover .select-btn:not(.accented):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.can-hover .select-btn:focus, .can-hover .select-btn:not(.accented):focus, .can-hover .select-btn:active, .can-hover .select-btn:not(.accented):active {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.textbox {
  width: 100%;
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  border-radius: .5rem;
  padding: .5rem 1rem;
  transition: all .2s;
}

.textbox:focus, .textbox:focus-within {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.Select-placeholder {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}

.Select-placeholder, .Select-input, .Select-value {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.error-message {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: .875rem;
  animation-name: fadein;
  animation-duration: .4s;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-16 {
  bottom: 4rem;
}

.right-4 {
  right: 1rem;
}

.top-4 {
  top: 1rem;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-2 {
  top: .5rem;
}

.right-3 {
  right: .75rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-12 {
  bottom: 3rem;
}

.top-6 {
  top: 1.5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-2 {
  left: .5rem;
}

.top-12 {
  top: 3rem;
}

.bottom-20 {
  bottom: 5rem;
}

.left-12 {
  left: 3rem;
}

.right-12 {
  right: 3rem;
}

.left-4 {
  left: 1rem;
}

.top-3 {
  top: .75rem;
}

.top-1 {
  top: .25rem;
}

.bottom-1 {
  bottom: .25rem;
}

.top-14 {
  top: 3.5rem;
}

.right-6 {
  right: 1.5rem;
}

.left-1\/2 {
  left: 50%;
}

.right-1\/2 {
  right: 50%;
}

.right-2\/3 {
  right: 66.6667%;
}

.left-1\/3 {
  left: 33.3333%;
}

.right-1\/3 {
  right: 33.3333%;
}

.left-2\/3 {
  left: 66.6667%;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.top-8 {
  top: 2rem;
}

.top-5 {
  top: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.bottom-8 {
  bottom: 2rem;
}

.-right-2 {
  right: -.5rem;
}

.top-\[21px\] {
  top: 21px;
}

.bottom-\[12px\] {
  bottom: 12px;
}

.left-\[12px\] {
  left: 12px;
}

.right-\[12px\] {
  right: 12px;
}

.left-6 {
  left: 1.5rem;
}

.isolate {
  isolation: isolate;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-0 {
  z-index: 0;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-30 {
  z-index: 30;
}

.order-last {
  order: 9999;
}

.order-first {
  order: -9999;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-start-3 {
  grid-column-start: 3;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-start-1 {
  grid-row-start: 1;
}

.float-right {
  float: right;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.-m-2 {
  margin: -.5rem;
}

.m-2 {
  margin: .5rem;
}

.m-1 {
  margin: .25rem;
}

.-m-1 {
  margin: -.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.-ml-px {
  margin-left: -1px;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-32 {
  margin-top: 8rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-3 {
  margin-left: .75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-0 {
  margin-top: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-0 {
  margin-left: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.-mb-\[3px\] {
  margin-bottom: -3px;
}

.-ml-1 {
  margin-left: -.25rem;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-0 {
  margin-right: 0;
}

.mt-9 {
  margin-top: 2.25rem;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-0 {
  margin-left: 0;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.\!inline {
  display: inline !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-20 {
  height: 5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.h-screen {
  height: 100vh;
}

.h-24 {
  height: 6rem;
}

.h-7 {
  height: 1.75rem;
}

.h-3 {
  height: .75rem;
}

.h-11 {
  height: 2.75rem;
}

.h-10 {
  height: 2.5rem;
}

.h-48 {
  height: 12rem;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-52 {
  height: 13rem;
}

.h-px {
  height: 1px;
}

.h-36 {
  height: 9rem;
}

.h-2 {
  height: .5rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-0\.5 {
  height: .125rem;
}

.h-9 {
  height: 2.25rem;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-60 {
  max-height: 15rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-0 {
  min-height: 0;
}

.w-full {
  width: 100%;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-20 {
  width: 5rem;
}

.w-12 {
  width: 3rem;
}

.w-24 {
  width: 6rem;
}

.w-16 {
  width: 4rem;
}

.w-4 {
  width: 1rem;
}

.w-14 {
  width: 3.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-3 {
  width: .75rem;
}

.w-28 {
  width: 7rem;
}

.w-0 {
  width: 0;
}

.w-11 {
  width: 2.75rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-64 {
  width: 16rem;
}

.w-auto {
  width: auto;
}

.w-1\/4 {
  width: 25%;
}

.w-0\.5 {
  width: .125rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-3\/12 {
  width: 25%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-36 {
  width: 9rem;
}

.w-48 {
  width: 12rem;
}

.w-96 {
  width: 24rem;
}

.w-72 {
  width: 18rem;
}

.w-\[300px\] {
  width: 300px;
}

.w-2 {
  width: .5rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1 {
  width: .25rem;
}

.w-40 {
  width: 10rem;
}

.w-80 {
  width: 20rem;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/2 {
  width: 50%;
}

.w-56 {
  width: 14rem;
}

.w-32 {
  width: 8rem;
}

.min-w-full {
  min-width: 100%;
}

.min-w-0 {
  min-width: 0;
}

.max-w-\[150px\] {
  max-width: 150px;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-\[250px\] {
  max-width: 250px;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.origin-center {
  transform-origin: center;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-top {
  transform-origin: top;
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-.5rem * calc(1 - var(--tw-space-x-reverse)) );
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)) );
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-b-md {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-r {
  border-right-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-cool-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(194 120 3 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(250 202 21 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(188 240 218 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(132 225 188 / var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 213 213 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(248 180 180 / var(--tw-border-opacity));
}

.border-cool-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity));
}

.border-cool-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-cool-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(224 36 36 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(155 28 28 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(252 233 106 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 159 110 / var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(195 221 253 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(205 219 254 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(141 162 251 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 245 247 / var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 140 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-cool-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(222 247 236 / var(--tw-bg-opacity));
}

.bg-cool-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-cool-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-cool-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-cool-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

.bg-cool-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity));
}

.bg-cool-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 232 232 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(164 202 254 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(155 28 28 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 253 234 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 247 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 140 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 220 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 138 76 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 30 46 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 36 36 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 122 85 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 242 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 160 8 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 90 31 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(208 56 1 / var(--tw-bg-opacity));
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 52 3 / var(--tw-bg-opacity));
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(138 44 13 / var(--tw-bg-opacity));
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 35 13 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 126 184 / var(--tw-bg-opacity));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 241 / var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 202 21 / var(--tw-bg-opacity));
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.fill-current {
  fill: currentColor;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-5 {
  padding: 1.25rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-8 {
  padding: 2rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-1 {
  padding: .25rem;
}

.p-3 {
  padding: .75rem;
}

.p-12 {
  padding: 3rem;
}

.p-1\.5 {
  padding: .375rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-middle {
  vertical-align: middle;
}

.align-\[-5px\] {
  vertical-align: -5px;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: .875rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-xs {
  font-size: .75rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-tight {
  line-height: 1.25;
}

.leading-4 {
  line-height: 1rem;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-cool-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-cool-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-cool-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity));
}

.text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(164 202 254 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(210 214 220 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity));
}

.text-cool-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(142 75 16 / var(--tw-text-opacity));
}

.text-cool-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(114 59 19 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(14 159 110 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity));
}

.text-cool-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(1 71 55 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(119 29 29 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(88 80 236 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(49 196 141 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(227 160 8 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(99 49 18 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(3 84 63 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(4 108 78 / var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(138 44 13 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 28 28 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(229 237 255 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(250 202 21 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(141 162 251 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 90 31 / var(--tw-text-opacity));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(195 221 253 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(180 52 3 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(159 166 178 / var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-75 {
  opacity: .75;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-100 {
  opacity: 1;
}

.opacity-10 {
  opacity: .1;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-outline-gray {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-solid {
  --tw-shadow: 0 0 0 2px currentColor;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(195 221 253 / var(--tw-ring-opacity));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-100 {
  transition-duration: .1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.focus-within\:z-10:focus-within {
  z-index: 10;
}

.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-solid:hover {
  border-style: solid;
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity));
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 131 248 / var(--tw-border-opacity));
}

.hover\:bg-cool-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.hover\:bg-cool-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 86 219 / var(--tw-bg-opacity));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(235 245 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 239 254 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-cool-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-cool-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 180 180 / var(--tw-bg-opacity));
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 213 213 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 178 / var(--tw-bg-opacity));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(188 240 218 / var(--tw-bg-opacity));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(132 225 188 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(159 166 178 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(104 117 245 / var(--tw-bg-opacity));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(195 221 253 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(81 69 205 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 47 63 / var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 245 255 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(141 162 251 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 159 110 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 82 82 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 237 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(63 131 248 / var(--tw-bg-opacity));
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(118 169 250 / var(--tw-bg-opacity));
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 196 141 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 106 / var(--tw-bg-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(26 86 219 / var(--tw-text-opacity));
}

.hover\:text-cool-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(63 131 248 / var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(118 169 250 / var(--tw-text-opacity));
}

.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgb(235 245 255 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity));
}

.hover\:text-cool-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(194 120 3 / var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(81 69 205 / var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(28 100 242 / var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity));
}

.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgb(35 56 118 / var(--tw-text-opacity));
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(54 47 120 / var(--tw-text-opacity));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(5 122 85 / var(--tw-text-opacity));
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(224 36 36 / var(--tw-text-opacity));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(249 128 128 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(159 166 178 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:shadow-outline-blue:hover {
  --tw-shadow: 0 0 0 3px #bfdbfe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-outline:hover {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(164 202 254 / var(--tw-border-opacity));
}

.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(118 169 250 / var(--tw-border-opacity));
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(210 214 220 / var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity));
}

.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(88 80 236 / var(--tw-border-opacity));
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(28 100 242 / var(--tw-border-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(104 117 245 / var(--tw-border-opacity));
}

.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(81 69 205 / var(--tw-border-opacity));
}

.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(180 198 252 / var(--tw-border-opacity));
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(159 166 178 / var(--tw-border-opacity));
}

.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(5 122 85 / var(--tw-border-opacity));
}

.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(227 160 8 / var(--tw-border-opacity));
}

.focus\:bg-cool-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.focus\:bg-cool-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity));
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:text-cool-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgb(22 30 46 / var(--tw-text-opacity));
}

.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(159 166 178 / var(--tw-placeholder-opacity));
}

.focus\:shadow-outline-blue:focus {
  --tw-shadow: 0 0 0 3px #bfdbfe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-gray:focus {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline:focus {
  --tw-shadow: 0 0 0 3px #76a9fa73;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-red:focus {
  --tw-shadow: 0 0 0 3px #fecaca80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-indigo:focus {
  --tw-shadow: 0 0 0 3px #c7d2fe80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-green:focus {
  --tw-shadow: 0 0 0 3px #a7f3d080;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-outline-yellow:focus {
  --tw-shadow: 0 0 0 3px #fde68a80;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(104 117 245 / var(--tw-ring-opacity));
}

.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(195 221 253 / var(--tw-ring-opacity));
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.active\:z-10:active {
  z-index: 10;
}

.active\:bg-cool-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.active\:bg-gray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.active\:bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity));
}

.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(244 245 247 / var(--tw-bg-opacity));
}

.active\:bg-indigo-200:active {
  --tw-bg-opacity: 1;
  background-color: rgb(205 219 254 / var(--tw-bg-opacity));
}

.active\:text-cool-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.active\:text-gray-800:active {
  --tw-text-opacity: 1;
  color: rgb(37 47 63 / var(--tw-text-opacity));
}

.active\:text-gray-100:active {
  --tw-text-opacity: 1;
  color: rgb(244 245 247 / var(--tw-text-opacity));
}

.active\:text-gray-700:active {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 100 242 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 214 220 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 80 236 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(104 117 245 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(66 56 157 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 66 159 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 245 247 / var(--tw-ring-opacity));
}

.group:focus .group-focus\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)) );
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-cool-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(226 232 240 / var(--tw-border-opacity));
  }

  .sm\:bg-cool-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pt-36 {
    padding-top: 9rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-7 {
    line-height: 1.75rem;
  }

  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(132 225 188 / var(--tw-border-opacity));
  }

  .sm\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgb(248 180 180 / var(--tw-border-opacity));
  }

  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(222 247 236 / var(--tw-bg-opacity));
  }

  .sm\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(253 232 232 / var(--tw-bg-opacity));
  }

  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  .md\:m-2 {
    margin: .5rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)) );
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }

  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)) );
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:w-80 {
    width: 20rem;
  }
}

.gm-style-cc {
  display: none;
}

.Select-control {
  cursor: text !important;
  --tw-border-opacity: 1 !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
  height: auto !important;
  border-radius: .5rem !important;
}

.Select-control:focus, .Select-control:focus-within {
  --tw-shadow: 0 0 0 3px #76a9fa73 !important;
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Select-control .Select-input {
  height: 40px;
}

.Select-control .Select-placeholder, .Select-control .Select-value {
  line-height: 40px !important;
}

.Select-control .Select-input > input {
  line-height: 24px;
}

.text-true-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.Sidekick {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.Sidekick .btn-blue {
  background-color: #5850ec;
  border: none;
}

.Sidekick .btn-blue:hover {
  background-color: #6775f5;
  border: none;
}

.Sidekick .btn-magic, .Sidekick .bg-cool-gray-600 {
  background: #5850ec;
}

.Select {
  position: relative;
}

.Select input::-webkit-contacts-auto-fill-button, .Select input::-webkit-credentials-auto-fill-button {
  display: none !important;
}

.Select input::-ms-clear {
  display: none !important;
}

.Select input::-ms-reveal {
  display: none !important;
}

.Select, .Select div, .Select input, .Select span {
  box-sizing: border-box;
}

.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: .35;
}

.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}

.Select.is-disabled > .Select-control:hover {
  box-shadow: none;
}

.Select.is-open > .Select-control {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Select.is-open > .Select-control .Select-arrow {
  border-width: 0 5px 5px;
  border-color: #0000 #0000 #999;
  top: -2px;
}

.Select.is-searchable.is-open > .Select-control, .Select.is-searchable.is-focused:not(.is-open) > .Select-control {
  cursor: text;
}

.Select.is-focused > .Select-control {
  background: #fff;
}

.Select.is-focused:not(.is-open) > .Select-control {
  background: #fff;
  border-color: #007eff;
  box-shadow: inset 0 1px 1px #00000013, 0 0 0 3px #007eff1a;
}

.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding-right: 42px;
}

.Select.has-value.Select--single > .Select-control .Select-value .Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover, .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #007eff;
  outline: none;
  text-decoration: underline;
}

.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus, .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  background: #fff;
}

.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}

.Select.Select--rtl {
  direction: rtl;
  text-align: right;
}

.Select-control {
  color: #333;
  cursor: default;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  display: table;
  position: relative;
  overflow: hidden;
}

.Select-control:hover {
  box-shadow: 0 1px #0000000f;
}

.Select-control .Select-input:focus {
  background: #fff;
  outline: none;
}

.Select-placeholder, .Select--single > .Select-control .Select-value {
  color: #aaa;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 34px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Select-input {
  height: 34px;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}

.Select-input > input {
  width: 100%;
  box-shadow: none;
  cursor: default;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: 8px 0 12px;
  line-height: 17px;
  display: inline-block;
}

.is-focused .Select-input > input {
  cursor: text;
}

.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}

.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}

.Select-loading-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  display: table-cell;
  position: relative;
}

.Select-loading {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  vertical-align: middle;
  border: 2px solid #ccc;
  border-right-color: #333;
  border-radius: 50%;
  animation: Select-animation-spin .4s linear infinite;
  display: inline-block;
  position: relative;
}

.Select-clear-zone {
  color: #999;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 17px;
  animation: Select-animation-fadeIn .2s;
  display: table-cell;
  position: relative;
}

.Select-clear-zone:hover {
  color: #d0021b;
}

.Select-clear {
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.Select--multi .Select-clear-zone {
  width: 17px;
}

.Select-arrow-zone {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
  display: table-cell;
  position: relative;
}

.Select--rtl .Select-arrow-zone {
  padding-left: 5px;
  padding-right: 0;
}

.Select-arrow {
  height: 0;
  width: 0;
  border: 5px solid #0000;
  border-top-color: #999;
  border-bottom-width: 2.5px;
  display: inline-block;
  position: relative;
}

.Select-control > :last-child {
  padding-right: 5px;
}

.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}

.Select .Select-aria-only {
  height: 1px;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  float: left;
  margin: -1px;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Select-menu-outer {
  box-sizing: border-box;
  max-height: 200px;
  width: 100%;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: -1px;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px #0000000f;
}

.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}

.Select-option {
  box-sizing: border-box;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  padding: 8px 10px;
  display: block;
}

.Select-option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Select-option.is-selected {
  color: #333;
  background-color: #007eff0a;
}

.Select-option.is-focused {
  color: #333;
  background-color: #007eff14;
}

.Select-option.is-disabled {
  color: #ccc;
  cursor: default;
}

.Select-noresults {
  box-sizing: border-box;
  color: #999;
  cursor: default;
  padding: 8px 10px;
  display: block;
}

.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}

.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px;
}

.Select--multi.has-value .Select-input {
  margin-left: 5px;
}

.Select--multi .Select-value {
  color: #007eff;
  vertical-align: top;
  background-color: #007eff14;
  border: 1px solid #007eff3d;
  border-radius: 2px;
  margin-top: 5px;
  margin-left: 5px;
  font-size: .9em;
  line-height: 1.4;
  display: inline-block;
}

.Select--multi .Select-value-icon, .Select--multi .Select-value-label {
  vertical-align: middle;
  display: inline-block;
}

.Select--multi .Select-value-label {
  cursor: default;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 2px 5px;
}

.Select--multi a.Select-value-label {
  color: #007eff;
  cursor: pointer;
  text-decoration: none;
}

.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}

.Select--multi .Select-value-icon {
  cursor: pointer;
  border-right: 1px solid #007eff3d;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 1px 5px 3px;
}

.Select--multi .Select-value-icon:hover, .Select--multi .Select-value-icon:focus {
  color: #0071e6;
  background-color: #0071e614;
}

.Select--multi .Select-value-icon:active {
  background-color: #007eff3d;
}

.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px;
}

.Select--multi.Select--rtl .Select-value-icon {
  border-left: 1px solid #007eff3d;
  border-right: none;
}

.Select--multi.is-disabled .Select-value {
  color: #333;
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}

.Select--multi.is-disabled .Select-value-icon:hover, .Select--multi.is-disabled .Select-value-icon:focus, .Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc;
}

@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}

.AssuredModal-background-enter.AssuredModal-background-enter-active, .AssuredModal-background-enter-done {
  opacity: 1 !important;
}

.AssuredModal-background-exit.AssuredModal-background-exit-active {
  opacity: 0;
}

.AssuredModal-enter.AssuredModal-enter-active, .AssuredModal-enter-done {
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  opacity: 1 !important;
}

.AssuredModal-exit.AssuredModal-exit-active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
}

.DateTime {
  max-width: 270px;
  -webkit-appearance: none;
  -moz-appearance: none;
  white-space: nowrap !important;
}

.DateTime::-webkit-inner-spin-button, .DateTime::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

@media (max-width: 640px) {
  .ClaimWorkflowListItem .DateTime {
    max-width: 130px;
  }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  border: 8px solid #0000;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  content: "";
  z-index: -1;
  border-width: 8px;
  border-bottom-color: #cfd8e3;
  left: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-top: none;
  border-bottom-color: #f1f5f9;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #cfd8e3;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before {
  border-top-color: #cfd8e3;
  bottom: -1px;
}

.react-datepicker-wrapper {
  text-align: center;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker-wrapper.react-datepicker-wrapper--wide {
  width: 100%;
  text-align: left;
}

.react-datepicker {
  --border-opacity: 1;
  border-width: 1px;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: .3rem;
  font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-bottom-width: 1px;
  border-color: rgba(207, 216, 227, var(--border-opacity));
  text-align: center;
  border-top-left-radius: .3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 6px 2px 0;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  width: 0;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  background: none;
  border: .45rem solid #0000;
  padding: 0;
  line-height: 1.7rem;
  position: absolute;
  top: 10px;
  overflow: hidden;
}

.react-datepicker__navigation:focus {
  outline: none;
}

.react-datepicker__navigation--previous {
  border-right-color: #cfd8e3;
  left: 10px;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #97a6ba;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #e6e6e6;
}

.react-datepicker__navigation--next {
  border-left-color: #cfd8e3;
  right: 10px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #97a6ba;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #e6e6e6;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  border-top-color: #ccc;
  top: 4px;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #ccc;
  top: -4px;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-left-width: 1px;
  border-color: rgba(207, 216, 227, var(--border-opacity));
  float: right;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #cfd8e3;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
  color: #fff;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
  color: #fff;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity));
  color: #fff;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity));
}

.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range, .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range, .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity));
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container input {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  --border-opacity: 1;
  border-width: 2px;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  width: 100%;
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.react-datepicker__input-container input:focus {
  outline-offset: 2px;
  --border-opacity: 1;
  border-width: 2px;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity));
  outline: 2px solid #0000;
  box-shadow: 0 0 0 3px #76a9fa73;
}

.react-datepicker-wrapper--narrow .react-datepicker__input-container input {
  min-width: 275px;
  text-align: center !important;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  float: right;
  border-width: .45rem;
  border-top-color: #ccc;
  margin-left: 20px;
  position: relative;
  top: 8px;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #cfd8e3;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  cursor: pointer;
  --border-opacity: 1;
  border-width: 2px;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: .375rem;
  padding-top: .125rem;
  padding-bottom: .125rem;
  padding-left: .25rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.react-datepicker__day--outside-month {
  opacity: .5;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #cfd8e3;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: .81rem solid #0000;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #e6e6e6;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #e6e6e6;
}

.ProgressBar {
  height: 10px;
  border-radius: 2rem;
  overflow: hidden;
}

.ProgressBar div {
  transition: width .3s ease-in-out;
}

.customCar {
  transform-origin: 50%;
  transform: rotate(180deg);
}

.PhoneInput {
  --PhoneInput-color--focus: #718096;
  --PhoneInputCountrySelectArrow-color--focus: #718096;
  --PhoneInputCountryFlag-borderColor--focus: #718096;
}

.PhoneInput .PhoneInputInput {
  outline: 0;
}

.PhoneInput .PhoneInputCountryIconUnicode {
  transform: scale(1.5);
}

.PhoneInput .PhoneInputCountry {
  margin-right: .6rem;
}

:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: .8;
  --PhoneInputInternationalIconGlobe-opacity: .65;
  --PhoneInputCountrySelect-marginRight: .35em;
  --PhoneInputCountrySelectArrow-width: .3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: .45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: #00000080;
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: #0000001a;
}

.PhoneInput {
  align-items: center;
  display: flex;
}

.PhoneInputInput {
  min-width: 0;
  flex: 1;
}

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
  display: block;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

.PhoneInputCountry {
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.PhoneInputCountrySelect {
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.PhoneInputCountrySelect[disabled], .PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
  display: block;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.Seatmap {
  --normal-color: #a0aec0;
  --light-color: #cbd5e0;
  --active-color: #718096;
}

.Seatmap.SeatmapSmall svg {
  width: 50%;
  margin: 0 auto;
}

.Seatmap svg {
  fill: var(--light-color);
  height: auto;
  max-width: 100%;
}

.Seatmap.interactive svg {
  fill: var(--normal-color);
}

.Seatmap.interactive g[id] {
  cursor: pointer;
  transition: all 50ms;
}

.Seatmap:not(.SeatmapAllowAnySeat) g#DRIVER {
  -webkit-user-select: none;
  user-select: none;
  fill: var(--light-color);
  cursor: initial;
}

.BaseNarrow {
  max-width: 500px;
}

.BaseFadeIn {
  transition-timing-function: ease-out;
  animation-name: fadein;
  animation-duration: .5s;
}

/*# sourceMappingURL=index.728f7b1c.css.map */
