/* Tesla Tenant */

body.tenant-tesla {
  @apply bg-black;
}
.tenant-tesla .btn,
.tenant-tesla .btn.btn-magic {
  @apply transition-colors duration-100 ease-in-out text-white uppercase font-medium !important;
  border: 1px solid #fff !important;
  background: transparent !important;
}
.tenant-tesla .btn:hover {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  ) !important;
}
.tenant-tesla .btn.btn-blue,
.tenant-tesla .btn.btn-magic {
  background: linear-gradient(
    180deg,
    rgba(131, 131, 131, 0.6) 0%,
    rgba(80, 80, 80, 0) 100%
  ) !important;
}
.tenant-tesla .btn.btn-blue:hover,
.tenant-tesla .btn.btn-magic:hover {
  background: linear-gradient(
      180deg,
      rgba(131, 131, 131, 0.6) 0%,
      rgba(80, 80, 80, 0) 100%
    )
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(102, 102, 102, 0.4) 0%,
      rgba(196, 196, 196, 0) 100%
    ) !important;
}
.tenant-tesla .Base {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(102, 102, 102, 0.4) 0%,
    rgba(196, 196, 196, 0) 100%
  );
}
.tenant-tesla .Nav {
  @apply bg-black;
}
.tenant-tesla .Nav .btn {
  border: none !important;
}

.tenant-tesla .text-black,
.tenant-tesla .text-gray-700,
.tenant-tesla .text-cool-gray-600,
.tenant-tesla .text-cool-gray-700,
.tenant-tesla .text-cool-gray-800,
.tenant-tesla .text-cool-gray-900 {
  @apply text-gray-50;
}
.tenant-tesla .text-cool-gray-500 {
  @apply text-gray-200;
}
.tenant-tesla .bg-cool-gray-300 {
  @apply bg-gray-800;
}
.tenant-tesla .bg-cool-gray-600 {
  @apply bg-gray-200;
}
.tenant-tesla .bg-cool-gray-100,
.tenant-tesla .hover\:.bg-cool-gray-100:hover {
  @apply bg-black;
}
.tenant-tesla .select-btn {
  @apply text-gray-200 bg-transparent border border-gray-200 !important;
}
.tenant-tesla .Modal {
  @apply bg-black !important;
}
.tenant-tesla .bg-blue-100,
.tenant-tesla .hover\:bg-blue-100:hover,
.tenant-tesla .hover\:bg-cool-gray-100:hover {
  background-color: #5f5f5f;
}
.tenant-tesla .border-blue-300 {
  border-color: #5f5f5f;
}
.tenant-tesla .text-blue-600 {
  color: white;
}
.tenant-tesla .border-blue-600 {
  border-color: white;
}
.tenant-tesla .border-cool-gray-600,
.tenant-tesla .border-cool-gray-400 {
  border-color: white;
}
.tenant-tesla {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
}
.tenant-tesla .ManagePolicy {
  color: #fff;
}
.tenant-tesla .ManagePolicy .bg-white {
  background-color: #313131;
}
.tenant-tesla .ManagePolicy .bg-gray-50 {
  background-color: #4c4c4c;
}
.tenant-tesla .ManagePolicy .text-gray-900,
.tenant-tesla .ManagePolicy .text-gray-800,
.tenant-tesla .ManagePolicy .text-gray-700,
.tenant-tesla .ManagePolicy .text-gray-600,
.tenant-tesla .ManagePolicy .text-gray-500 {
  color: #fff;
}
.tenant-tesla .ManagePolicy .text-indigo-600 {
  color: #bebbf9;
}
.tenant-tesla .ManagePolicy .bg-blue-600 {
  background-color: #5e5e5e;
}
.tenant-tesla .ManagePolicy .bg-blue-400 {
  background-color: #5e5e5e;
}
.tenant-tesla .bg-blue-300,
.tenant-tesla .bg-blue-500 {
  background-color: #1b1b1b;
}
.tenant-tesla .text-blue-500 {
  color: #fff;
}
.tenant-tesla .form-checkbox {
  @apply bg-gray-400;
}
.tenant-tesla .bg-green-100 {
  @apply bg-black border-white text-white border;
}
.tenant-tesla .text-green-600 {
  @apply text-white;
}
@screen sm {
  .tenant-tesla .ManagePolicy .sm\:bg-cool-gray-50 {
    background-color: #4e4e4e;
  }
}

/* USAA Tenant */

.tenant-usaa .text-cool-gray-600,
.tenant-usaa .text-cool-gray-700,
.tenant-usaa .textbox,
.tenant-usaa .Select-multi-value-wrapper,
.tenant-usaa .Select-value,
.tenant-usaa .Select-value-label,
.tenant-usaa .Select-input,
.tenant-usaa .Dropdown,
.tenant-usaa .form-checkbox {
  color: #003a63 !important;
}
.tenant-usaa .btn-blue {
  background-color: #003a63;
  border: none;
}
.tenant-usaa .btn-blue:hover {
  background-color: #00668a;
  border: none;
}
.tenant-usaa .btn-magic {
  background: linear-gradient(
    180deg,
    rgba(0, 102, 138, 1.5) 0%,
    rgba(18, 57, 91, 0.8) 100%
  ) !important;
}

/* Mercury Tenant */

.tenant-mercury .btn-blue {
  background-color: #991b1e;
  border: none;
}
.tenant-mercury.can-hover .btn-blue:hover,
.tenant-mercury.can-hover .btn-blue.btn-disabled:hover {
  background-color: #77080a;
  border: none;
}
.tenant-mercury .btn-magic {
  background: linear-gradient(90deg, #941a1b 0%, #77080a 100%) !important;
}
